import React from "react"
import Button from "./button.js"

const rowStyle = {
  display: "flex",
  height: "100%"
}

const columnStyle = {
  flex: "50%",
  height: "100%"
}

const menuStyle = {
  marginTop: 0,
  height: "100px",
  width: "100%",
  backgroundColor: "#d6edec"
}

class Header extends React.Component {

  constructor(props){
    super(props);
  }

  handleAction = (e) => {
    //pass the page title back up to the parent (page.js)
    this.props.currentPage(e)
  }

  render() {
    return (
      <div style={menuStyle}>
        <div style={rowStyle}>
          <Button clicked={this.handleAction} content={this.props.homeContent} children='Home'></Button>
          <Button clicked={this.handleAction} content={this.props.treatmentsContent} children='Treatments'></Button>
          <Button clicked={this.handleAction} content={this.props.aboutContent} children='About'></Button>
        </div>
      </div>
    )
  }
}

export default Header
