import React from "react"
import "./page.css"

const listData = [
  "Back pain – lower back pain, sciatica, disc problems and more",
  "Pains and sprains in ankles, knees, shoulder groin, hip",
  "Post-operative rehabilitation - (spinal, shoulder and knee surgery, joint replacements)",
  "Total knee replacement rehabilitation",
  "Achilles Tendinopathy",
  "Cruciate ligament rehabilitation",
  "Tennis elbow",
  "Carpal tunnel syndrome",
  "Bursitis",
  "Injuries with an uncertain diagnosis",
  "Post spinal surgery",
  "Post trauma, including fractures",
  "Chronic pain",
  "Repetitive strain injuries",
  "Arthritis"
]

const listItems = listData.map((listItem, idx) => {
      return <li key={idx}>{listItem}</li>;
});

export default function Home({}) {
  return (
    <p className="LinkStyle">
      Melissa is a Sport Rehabilitator, focusing on biomechanics, exercise rehabilitation and returning clients to a high level of functionality. Melissa provides a range of treatments including massage, injury rehabilitation, biomechanics coaching, taping and strapping.
      <br />
      <br />
        <span>Available for massage, rehabilitation and home visits. More information under the treatments tab.</span>
      <br/ >
      <br />
      A Sport Rehabilitator can treat many injuries including:
      <br/ >
      <ul id='treatmentList'>{listItems}</ul>
    </p >
  )
}
