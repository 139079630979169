import React from "react"
import "./page.css"

export default function About({}) {
  return (
    <p className="LinkStyle">
      Melissa studied sports rehabilitation at the University of Cumbria, obtaining a First Class Degree with Honours in 2021. Alongside her degree she completed a Level 1 Qualification in rocktape application and the Level 2 PHICIS Rugby Football Union course.
      <br />
      <br />
      Always aiming to improve her treatment options, she has recently passed a Hot Stone Massage Diploma course, and aims to complete both the NSCA Tactical Strength and Conditioning Facilitator exam and a pregnancy massage course in 2022.
      <br/ >
      <br/ >
      Melissa believes in listening to individuals and their stories, using education to reduce fear of movement, aiming to restore function and decrease pain.
    </p >
  )
}
