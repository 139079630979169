import * as React from "react"
import Page from "../components/page.js"
import Booking from "../components/booking.js"
import Home from "../components/home.js"
import About from "../components/about.js"
import Hi from "../images/hi.jpg"
// import Treatments from "../data/treatments.json"
import "typeface-benchnine"
import "../components/generic.css"

// pink = #c03b5a (title)
// blue = #d6edec (background)
// gren = #63a499 (info/subheader)

const pageMapping = {
    "Home": <Home/>,
    "Treatments": <Booking portrait={true}/>,
    "About": <About/>
}

// styles
const pageStyles = {
  color: "#c03b5a",
  fontFamily: "BenchNine, sans-serif, serif",
  height: "100%",
  minWidth: "300px"
}

var rowStyle = {
  display: "flex",
  height: "100%",
}

const columnStyle = {
  display: "flex",
  height: "100%",
  padding: "10px",
  alignContent: "stretch",
  justifyContent: "center",
  maxHeight: "-webkit-fill-available",
}

const scrollableColumn = {
  ...columnStyle,
  overflowY: "scroll",
  flex: 1
}

const imageColumn = {
  ...columnStyle,
  flex: "0 0"
}

const headingStyles = {
  marginTop: 0,
  marginBottom: 32,
  textAlign: "center",
  display: "flex",
  justifyContent: "center"
}

const bigBox = {
  height: "calc( 100vh - 180px)"
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 24,
  lineHeight: 1.25,
  // display: "flex",
  flexDirection: "column"
}

const imageStyle = {
  display: "flex",
  height: "100%",
  maxHeight: "500px",
  width: "100%",
  borderRadius: "300px",
  objectFit: "cover"
}

const imageWrapper = {
  display: "flex",
  verticalAlign: "center",
  alignItems: "stretch",
  marginRight: "0px",
  justifyContent: "center",
  maxWidth: "300px",
  minWidth: "200px",
  width: "40vw",
  transition: "0.2s",
  padding: "10px",
  flex: "0 0 auto",
  alignSelf: "start"
}

// markup
class IndexPage extends React.Component {
  constructor() {
    super();
    this.state = {
      'pageName':'Home',
      'renderedComponent': Home,
      'portrait': true
    }
  };

  isBrowser = typeof window !== "undefined"

  checkSize(isBrowser, win) {
    if (isBrowser) {
      var element = document.getElementById("pageContainer")
      var imageColumn = document.getElementById("imageColumn")
      var image = document.getElementById("hi")
      var scrollCol = document.getElementById("scrollableColumn")
      var isPortrait = (window.innerHeight/window.innerWidth >= 1)
      this.setState({'portrait': !isPortrait}, () => {
        this.setState({
          'pageMapping': {
              "Home": <Home/>,
              "Treatments": <Booking portrait={this.state.portrait}/>,
              "About": <About/>
          }
        })
      })
      // this.setState({
      //   'pageMapping': {
      //       "Home": <Home/>,
      //       "Treatments": <Booking portrait={this.state.portrait}/>,
      //       "About": <About/>
      //   }
      // })
      var isLargeDevice = (window.innerWidth >= (1200 / window.devicePixelRatio) )
        if (win || (!isLargeDevice && isPortrait)) {
          // set items into a column (for smaller portrait screens)
          element.style.flexDirection="column";
          element.style.overflow="scroll";
          imageColumn.style.flex="0 0" //0.8
          document.getElementById('scrollableColumn').onscroll = this.changeImageSize.bind(this);
        } else if (isLargeDevice || !isPortrait) {
          // set items side-by-side if in landscape
          element.style.flexDirection="row";
          element.style.overflow="hidden";
          imageColumn.style.removeProperty('flex');
          document.getElementById('scrollableColumn').onscroll = null
          image.style.maxWidth = "25vw"
          image.style.minWidth = "200px"
      }
    }
  }

  changeImageSize(){
    var imageElement = document.getElementById("hi")
     if (document.getElementById("scrollableColumn").scrollTop > 50) {
       imageElement.style.maxWidth = "120px"
       imageElement.style.minWidth = "120px"
     } else {
       imageElement.style.maxWidth = "400px"
       imageElement.style.minWidth = "200px"
     }
   }

  eventListener = () => {
    this.checkSize(this.isBrowser, window.matchMedia("(max-width: 600px)").matches);
  }

  changePage = (page) => {
    //Handle buttons being clicked
    this.setState({"renderedComponent": pageMapping[page]})
  }

  componentDidMount(){
    this.setState({
      'pageMapping': {
          "Home": <Home/>,
          "Treatments": <Booking portrait={this.state.portrait}/>,
          "About": <About/>
      }
    }, () => {
      window.addEventListener('storage', function() {
      })
      const script = document.createElement("script")
      script.async = true;
      script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=X6CjbE"
      document.body.appendChild(script);
      if (this.isBrowser) {
        var win = window.matchMedia("(max-width: 600px)").matches
        this.checkSize(this.isBrowser, win)
        window.addEventListener('resize', this.eventListener.bind(this))
        // check if there's a "Page" entry in sessionStorage
        if (window.sessionStorage.getItem('page')) {
          this.setState({
            'pageName': window.sessionStorage.getItem('page'),
            'renderedComponent': this.state.pageMapping[window.sessionStorage.getItem('page')]
          })
        } else {
          // start fresh
          this.setState({
            'pageName': 'Home',
            'renderedComponent': this.state.pageMapping['Home']
          })
        }
      }
    })
  }

  componentWillUnmount(){
    //Do this otherwise it'll crash on other pages.
    window.removeEventListener('resize', this.eventListener())
  }

  render() {
    return (
      <main style={pageStyles}>
        <title>Reactive Rehab</title>
        <Page changePage={this.changePage}>
        <div style={bigBox}>
          <div id="pageContainer" style={rowStyle}>
            <div style={imageColumn} id="imageColumn">
              <div id="hi" style={imageWrapper}>
                <img style={imageStyle} alt="" src={Hi}></img>
              </div>
            </div>
            <div id="scrollableColumn" style={scrollableColumn}>
              <div style={{flex: 1}}>
                <h1 id='header' style={headingStyles}>Welcome to Reactive Rehab</h1>
                <span id='spanText' style={descriptionStyle}>
                  {this.state.renderedComponent}
                </span>
              </div>
            </div>
          </div>
        </div>
        </Page>
      </main>
    )
  }
}

export default IndexPage
