import React from "react"
import fbIcon from "../images/fb.svg"
import emailIcon from "../images/email.svg"

const footerStyle = {
  position: "fixed",
  bottom: "0px",
  marginTop: 0,
  height: "80px",
  width: "100%",
  backgroundColor: "#d6edec",
}

const rowStyle = {
  display: "flex",
  alignItems: "center",
  height: "100%"
}

const iconWrapper = {
  display: "flex",
  alignItems: "center",
  height: "100%",
  marginLeft: "20px",
  marginRight: "0px",
}

const iconStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "50px",
}

export default function Footer({}) {
  return (
    <div style={footerStyle}>
      <div style={rowStyle}>
        <div style={iconWrapper}>
          <img style={iconStyle} onClick={() =>
            {window.open("https://www.facebook.com/Reactive-Rehab-100293872422930")}} src={fbIcon}/>
        </div>
        <div style={iconWrapper}>
          <img style={iconStyle}  onClick={() => {window.open("mailto:info@reactiverehab.one")}} src={emailIcon}/>
        </div>
      </div>
    </div>
  )
}
