import React from "react"
import "./page.css"

const bookingStylePortrait = {
  display: "flex",
  width: "100%",
  minHeight: "700px",
  height: "100%"
}

const bookingStyleLandscape = {
  ... bookingStylePortrait,
  minWidth: "60vw",
  maxWidth: "100vw",
}

const divStyle = {
  display: "flex",
  flexDirection: "column"
}

const bookingUrl = "https://app.rehabguru.com/appointments/f2de4db8d3ad5bf39d3e4ee1dadcc962%3A79110a6a8f9cec44cfa420b6dba838cb37067702226a086072c82edd50fb38f57430efa0868532830fcea452f801bcf8"

// const bookingStylePortrait = {
//   display: "flex",
//   width: "100%",
//   minHeight: "700px",
//   height: "100%"
// }

class Booking extends React.Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <div style={divStyle}>
        <p class="LinkStyle">
          <ul>
          <li>Please note our Friday clinic is at North Berwick Rugby Club. This clinic is run separately to the club and further details about your appointment will be sent in your confirmation email.</li>
          <li>Bookings on a Tuesday and Thursday evening are reserved for current NB rugby 1st XV and U18 players. If you wish to book in a younger player please get in touch first.</li>
          <li>If you are interested in event cover e.g tournaments, marathons, corporate sessions, please email and get in touch!</li>
          <li>Home visits are subject to availability and will include an extra charge depending on location. Please get in touch before booking anything.</li>
          <li>We have introduced a cancellation policy, if you cancel within 24 hours of your appointment you will be invoiced 50% of your appointments full price.</li>
          </ul>
        </p>
        <iframe class={this.props.portrait ? "bookingStylePortrait" : "bookingStyleLandscape"} style={this.props.portrait ? bookingStylePortrait : bookingStyleLandscape} src={bookingUrl} frameborder="0"></iframe>
      </div>
    )
  }
}

export default Booking
