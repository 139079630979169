import Header from "./header.js"
import Footer from "./footer.js"
import React from "react"

class Page extends React.Component {

  constructor(props){
    super(props)
  }

  selectedPage = (page) => {
    this.props.changePage(page)
  }

  render() {
    return (
      <div>
        <Header currentPage={this.selectedPage}></Header>
        {this.props.children}
        <Footer></Footer>
      </div>
    )
  }
}

export default Page
